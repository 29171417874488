











import Vue from "vue";

import TraitMapping from "./TraitMapping.vue";

import * as docs from "@/resources/docs/docs";

export default Vue.extend({
  name: "Home",
  components: {
    TraitMapping,
  },
  data() {
    return {
      docs: docs,
    };
  },
  computed: {
    //
  },
  watch: {
    //
  },
  mounted: async function (): Promise<void> {
    //
  },
  methods: {
    //
  },
});
